<template>
    <!-- <vue3-seamless-scroll></vue3-seamless-scroll> -->
    <div class="left_boottom_wrap beautify-scroll-def"  :class="{ 'overflow-y-auto': !indexConfig.leftBottomSwiper }">

      <Vue3SeamlessScroll
        :list="state.list"
        v-model="state.scroll"
        :singleHeight="state.defaultOption.singleHeight"
        :step="state.defaultOption.step"
        :limitScrollNum="state.defaultOption.limitScrollNum"
        :hover="state.defaultOption.hover"
        :singleWaitTime="state.defaultOption.singleWaitTime"
        :wheel="state.defaultOption.wheel"
      >
        <ul class="left_boottom">
          <li class="left_boottom_item" v-for="(item, i) in state.list" :key="i">
            <span class="orderNum doudong" style="color: #fff;">{{ i + 1 }}</span>
            <div class="inner_right">
              <div class="dibu"></div>
              <div class="flex">
                <div class="info">
                  <span class="labels">{{ t('tran_1146') }}</span>
                  <span class="text-content zhuyao doudong wangguan">
                    {{ item.licensePlate }}</span
                  >
                </div>
                <!-- <div class="info">
                  <span class="labels">{{ t('tran_999') }}</span>
                  <span class="text-content" style="font-size: 12px">
                    {{ item.createTime }}</span
                  >
                </div> -->
                <div class="info" style="flex: 1;align-self: center;">
                    <span class="labels">{{ t('tran_2108') }}</span>
                    <span class="text-content" style="font-size: 12px">
                        {{ item.pay }}</span
                    >
                </div>
              </div>

              <span
                class="types doudong"
                :class="{
                  typeRed: item.status == 0,
                  typeGreen: item.status == 1,
                }"
                >{{ item.status == 1 ? t('tran_1963') : t('tran_477') }}</span
              >

              <div class="info addresswrap">
                <span class="labels">{{ t('tran_999') }}</span>
                <span class="text-content ciyao" style="font-size: 12px">
                  {{ item.createTime }}</span
                >
              </div>
            </div>
          </li>
        </ul>
      </Vue3SeamlessScroll>
    </div>
</template>

<script setup>
import i18n from "@/language/i18n";
const { t } = i18n.global;
import { Vue3SeamlessScroll } from "vue3-seamless-scroll"
import { useSettingStore } from "@/store/modules/parking.js";
import { storeToRefs } from "pinia";
import { reactive} from 'vue'
const settingStore = useSettingStore();
const { defaultOption, indexConfig } = storeToRefs(settingStore);

// const props = defineProps({
//     list: Array
// })
const state = reactive({
    list: [
        {
            id: 1,
            licensePlate: t('tran_2071'),
            createTime: '2023-7-23 13:23:00',
            status: 0,
            pay: '￥20'
        },
        {
            id: 2,
            licensePlate: t('tran_2071'),
            createTime: '2023-7-23 13:23:00',
            status: 0,
            pay: '￥20'
        },
        {
            id: 3,
            licensePlate: t('tran_2071'),
            createTime: '2023-7-23 13:23:00',
            status: 1,
            pay: '￥20'
        },
        {
            id: 4,
            licensePlate: t('tran_2071'),
            createTime: '2023-7-23 13:23:00',
            status: 0,
            pay: '￥20'
        },
        {
            id: 5,
            licensePlate: t('tran_2071'),
            createTime: '2023-7-23 13:23:00',
            status: 1,
            pay: '￥20'
        }
    ],
    scroll: true,
    defaultOption: {
        ...defaultOption.value,
        singleHeight: 256,
        limitScrollNum: 4,
    },
})
</script>
<style scoped lang="scss">
.left_boottom_wrap {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.doudong {
    overflow: hidden;
    backface-visibility: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}

.left_boottom {
    width: 100%;
    height: 100%;

    .left_boottom_item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        font-size: 14px;
        margin: 10px 0;

        .orderNum {
            margin: 0 16px 0 -20px;
        }
        .flex {
            display: flex;
            // justify-content: space-around;
        }

        .info {
            margin-right: 10px;
            display: flex;
            align-items: center;
            color: #fff;

            .labels {
                flex-shrink: 0;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.6);
            }

            .zhuyao {
                // color: #bcbdbf;
                font-size: 15px;
            }

            .ciyao {
                color: rgba(255, 255, 255, 0.8);
            }

            .warning {
                color: #e6a23c;
                font-size: 15px;
            }
        }

        .inner_right {
            position: relative;
            height: 100%;
            width: 380px;
            flex-shrink: 0;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .dibu {
                position: absolute;
                height: 2px;
                width: 104%;
                background-image: url("@/assets/img/zuo_xuxian.png");
                bottom: -10px;
                left: -2%;
                background-size: cover;
            }

            .addresswrap {
                width: 100%;
                display: flex;
                margin-top: 8px;
            }
        }

        .wangguan {
            color: #1890ff;
            font-weight: 900;
            font-size: 15px;
            width: 80px;
            flex-shrink: 0;
        }

        .time {
            font-size: 12px;
            // color: rgba(211, 210, 210,.8);
            color: #fff;
        }

        .address {
            font-size: 12px;
            cursor: pointer;
            // @include text-overflow(1);
        }

        .types {
            width: 30px;
            flex-shrink: 0;
        }

        .typeRed {
            color: #fc1a1a;
        }

        .typeGreen {
            color: #29fc29;
        }
    }
}</style>
