import request from '@/utils/request'

// 查询系统大楼信息列表
export function listFloor(query) {
  return request({
    url: '/dataCenter/system/floor/list',
    method: 'get',
    params: query
  })
}


// 查询系统大楼信息列表
export function listAllFloor(query) {
  return request({
    url: '/dataCenter/system/floor/allList',
    method: 'get',
    params: query
  })
}

// 查询系统大楼信息详细
export function getFloor(id) {
  return request({
    url: '/dataCenter/system/floor/' + id,
    method: 'get'
  })
}

// 新增系统大楼信息
export function addFloor(data) {
  return request({
    url: '/dataCenter/system/floor',
    method: 'post',
    data: data
  })
}

// 修改系统大楼信息
export function updateFloor(data) {
  return request({
    url: '/dataCenter/system/floor',
    method: 'put',
    data: data
  })
}

// 删除系统大楼信息
export function delFloor(id) {
  return request({
    url: '/dataCenter/system/floor/' + id,
    method: 'delete'
  })
}

// 查询部门列表（排除节点）
export function listFloorExcludeChild(id) {
  return request({
    url: `/dataCenter/system/floor/list/exclude/` + id,
    method: 'get'
  })
}


// 获取大楼树形结构
export function floorTree() {
  return request({
    url: '/dataCenter/system/floor/getFloorTree',
    method: 'get'
  })
}
