<template>
    <div id="main" ref="main" style="width: 100%;height: 450px;"></div>
</template>

<script setup>
import i18n from "@/language/i18n";
const { t } = i18n.global;
import * as echarts from 'echarts';
import { onMounted, ref } from 'vue'
let main = ref()
onMounted(() => {
    // let chartDom = document.getElementById('main');
    let myChart = echarts.init(main.value);
    let base = +new Date(2000, 9, 3);
    let oneDay = 24 * 3600 * 1000;
    let data = [[base, Math.random() * 300]];
    for (let i = 1; i < 1000; i++) {
        let now = new Date((base += oneDay));
        data.push([+now, Math.round((Math.random() - 0.5) * 20 + data[i - 1][1])]);
    }

    window.addEventListener(
        'resize',
        () => {
            myChart.resize()
        },
        false
    )
    let options = {
        color: ['#0bc0c4'],
        tooltip: {
            trigger: 'axis',
            position: function (pt) {
                return [pt[0], '10%'];
            }
        },
        // title: {
        //     left: '2%',
        //     text: t('null'),
        //     textStyle: {
        //         color: '#fff'
        //     }
        // },
        toolbox: {
            // feature: {
            //   // dataZoom: {
            //   //   yAxisIndex: 'none'
            //   // },
            //   restore: {},
            //   saveAsImage: {}
            // }
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,

        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            splitLine: false,
        },
        dataZoom: [
                // {
                //     type: 'slider',//数据滑块
                //     start:0,
                //     // minSpan:8,    //5min
                //     // minSpan:16,   //10min
                //     // minSpan:24,   //15min
                //     // minSpan:50,   //30min
                //     end: 20,
                //     dataBackground:{
                //         lineStyle:{
                //             color:'#95BC2F'

                //         },
                //         areaStyle:{
                //             color:'#95BC2F',
                //             opacity:1,
                //         }
                //     },
                //     // fillerColor:'rgba(255,255,255,.6)'
                // },
                {
                    type:'inside'//使鼠标在图表中时滚轮可用
                }
            ],
        series: [
            {
                name: t('tran_2439'),
                type: 'line',
                smooth: true,
                symbol: 'none',
                        label: {
                            show: true,
                            color: '#4992ff'
                        },

                areaStyle: {
                    //折线图颜色半透明
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: 'rgba(134, 251, 192, 0.5)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgba(134, 251, 192, 0.1)' // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    }

                },
                data: data
            }
        ]
    }

    myChart.setOption(options);
}
)
</script>

<style scoped></style>
