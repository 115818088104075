
import i18n from "@/language/i18n";
const { t } = i18n.global;import { generateUUID } from '@/utils/ruoyi'
import * as DictDataApi from '@/api/system/dict/data'
import { localeProps, makeRequiredRule } from '@/components/FormCreate/src/utils'
import { selectRule } from '@/components/FormCreate/src/config/selectRule'
import { cloneDeep } from 'lodash-es'

/**
 * 字典选择器规则，如果规则使用到动态数据则需要单独配置不能使用 useSelectRule
 */
export const useDictSelectRule = () => {
  const label = t('tran_2939')
  const name = 'DictSelect'
  const rules = cloneDeep(selectRule)
  const dictOptions = ref([]) // 字典类型下拉数据
  onMounted(async () => {
    const data = await DictDataApi.listData().rows
    if (!data || data.length === 0) {
      return
    }
    dictOptions.value =
      data?.map((item) => ({
        label: item.name,
        value: item.type
      })) ?? []
  })
  return {
    icon: 'icon-doc-text',
    label,
    name,
    rule() {
      return {
        type: name,
        field: generateUUID(),
        title: label,
        info: '',
        $required: false
      }
    },
    props(_, { t }) {
      return localeProps(t, name + '.props', [
        makeRequiredRule(),
        {
          type: 'select',
          field: 'dictType',
          title: t('tran_618'),
          value: '',
          options: dictOptions.value
        },
        {
          type: 'select',
          field: 'valueType',
          title: t('tran_251'),
          value: 'str',
          options: [
            { label: t('tran_404'), value: 'int' },
            { label: t('tran_2560'), value: 'str' },
            { label: t('tran_3229'), value: 'bool' }
          ]
        },
        ...rules
      ])
    }
  }
}
