
import i18n from "@/language/i18n";
const { t } = i18n.global;import { generateUUID } from '@/utils/ruoyi'
import { localeProps, makeRequiredRule } from '@/components/FormCreate/src/utils'

export const useUploadFileRule = () => {
  const label = t('tran_1235')
  const name = 'UploadFile'
  return {
    icon: 'icon-upload',
    label,
    name,
    rule() {
      return {
        type: name,
        field: generateUUID(),
        title: label,
        info: '',
        $required: false
      }
    },
    props(_, { t }) {
      return localeProps(t, name + '.props', [
        makeRequiredRule(),
        {
          type: 'select',
          field: 'fileType',
          title: t('tran_371'),
          value: ['doc', 'xls', 'ppt', 'txt', 'pdf'],
          options: [
            { label: 'doc', value: 'doc' },
            { label: 'xls', value: 'xls' },
            { label: 'ppt', value: 'ppt' },
            { label: 'txt', value: 'txt' },
            { label: 'pdf', value: 'pdf' }
          ],
          props: {
            multiple: true
          }
        },
        {
          type: 'switch',
          field: 'autoUpload',
          title: t('tran_416'),
          value: true
        },
        {
          type: 'switch',
          field: 'drag',
          title: t('tran_1809'),
          value: false
        },
        {
          type: 'switch',
          field: 'isShowTip',
          title: t('tran_1250'),
          value: true
        },
        {
          type: 'inputNumber',
          field: 'fileSize',
          title: '大小限制(MB)',
          value: 5,
          props: { min: 0 }
        },
        {
          type: 'inputNumber',
          field: 'limit',
          title: t('tran_2246'),
          value: 5,
          props: { min: 0 }
        },
        {
          type: 'switch',
          field: 'disabled',
          title: t('tran_1093'),
          value: false
        }
      ])
    }
  }
}
