import {defineStore} from "pinia";


const useFloorStore = defineStore(
  'floor',
  {
    state: () => ({
      floorList: []
    }),
    actions: {
      getFloorList(floorId) {
        if (floorId == null && floorId == "") {
          return null;
        }
        try {
          for (let i = 0; i < this.floorList.length; i++) {
            if (this.floorList[i].key == floorId) {
              return this.floorList[i].value;
            }
          }
        } catch (e) {
          return null;
        }
      },

      setFloorList(key, value) {
        if (key !== null && key !== "") {
          this.floorList.push({
            key: key,
            value: value
          });
        }
      },

      removeFloorList(key) {
        let bln = false;
        try {
          for (let i = 0; i < this.floorList.length; i++) {
            if (this.floorList[i].key == key) {
              this.floorList.splice(i, 1);
              return true;
            }
          }
        } catch (e) {
          bln = false;
        }
        return bln;
      },
      // 清空字典
      cleanFloorList() {
        this.floorList = [];
      },
      // 初始字典
      initFloorList() {
      }
    }
  })

export default useFloorStore
