<template>
  <div id="main" class="id" ref="main"></div>
</template>

<script setup>
import i18n from "@/language/i18n";
const { t } = i18n.global;
import * as echarts from 'echarts'
import { onMounted, ref } from 'vue'
let main = ref()
const props = defineProps({
  // props: Object
  title: String,
  listX: Array,
    listY1: Array,
    listY2: Array
})

let myChart
let timer = null
const myChartInit = () => {
  myChart = echarts.init(main.value)
  const colors = ['#5470C6', '#04a7e9']
  let options = {
    color: colors,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },
    grid: {
      right: '20%'
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,.1)',
      textStyle: {
        color: '#ffffff',
        fontSize: 18,
        fontWeight: 'normal'
      },
      axisPointer: {
        type: 'shadow',
        label: {
          fontSize: 16,
          show: true,
          backgroundColor: '#7B7DDC'
        }
      }
    },
    legend: {
      data: [t('null')],
      textStyle: {
        color: '#ccc'
      }
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: '#ccc'
          }
        },
        // prettier-ignore
        data: props.listX.value
      }
    ],
    yAxis: [
      {
        type: 'value',
        // name: t('tran_27'),
        position: 'right',
        alignTicks: true,
        splitLine: false,
        axisLabel: {
          formatter: '{value}%'
        },
        axisLine: {
          lineStyle: {
            color: '#ccc'
          }
        }
      },
      {
        type: 'value',
        name: '单位/个',
        position: 'left',
        alignTicks: true,
        splitLine: false,
        axisLabel: {
          formatter: '{value}'
        },
        axisLine: {
          lineStyle: {
            color: '#ccc'
          }
        }
      }
    ],
    series: [
      {
        name: t('tran_27'),
        type: 'bar',
        barWidth: 10,
        itemStyle: {
          normal: {
            barBorderRadius: 5,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(156,107,211,1)' },
              // {offset: 0.2, color: 'rgba(156,107,211,0.5)'},
              { offset: 1, color: 'rgba(156,107,211,0)' }
            ])
          }
        },
        z: -12,
        data: props.listY1.value
      },
      {
        name: t('tran_1484'),
        type: 'line',
        yAxisIndex: 1,
        smooth: true,
        symbolSize: 10,
        itemStyle: {
          normal: {
            color: '#F02FC2'
          }
        },
        data: props.listY2.value
      }
    ]
  }
  var app = {
    currentIndex: -1
  }
  timer = setInterval(function () {
    var dataLen = options.series[0].data.length

    // 取消之前高亮的图形
    myChart.dispatchAction({
      type: 'downplay',
      seriesIndex: 0,
      dataIndex: app.currentIndex
    })
    app.currentIndex = (app.currentIndex + 1) % dataLen
    // 高亮当前图形
    myChart.dispatchAction({
      type: 'highlight',
      seriesIndex: 0,
      dataIndex: app.currentIndex
    })
    // 显示 tooltip
    myChart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: app.currentIndex
    })
  }, 3000)
  if (options && typeof options === 'object') {
    myChart.setOption(options, true)
  }

  window.onresize = function () {
    myChart.resize()
  }
  myChart.setOption(options)
}
const clear =() => {
    myChart.clear()
    clearInterval(timer)
}
defineExpose({
    myChartInit,
    clear
})
// onMounted(() => {
//   // let chartDom = document.getElementById('main');
//   myChartInit()
// })
</script>

<style scoped>
.id {
  width: 100%;
  height: 100%;
}
</style>
