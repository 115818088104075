import request from '@/utils/request'


const prefix="/admin"
// 查询OSS对象存储列表
export function listOss(query) {
  return request({
    url: `${prefix}/system/oss/list`,
    method: 'get',
    params: query
  })
}

// 查询OSS对象基于id串
export function listByIds(ossId) {
  return request({
    url: `${prefix}/system/oss/listByIds/` + ossId,
    method: 'get'
  })
}

// 删除OSS对象存储
export function delOss(ossId) {
  return request({
    url: `${prefix}/system/oss/` + ossId,
    method: 'delete'
  })
}

