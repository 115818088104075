<!-- {{ t('null') }}-->
<template>
  <el-select v-if="selectType === 'select'" class="w-1/1" v-bind="attrs">
    <el-option
      v-for="(dict, index) in getDictOptions"
      :key="index"
      :label="dict.label"
      :value="dict.value"
    />
  </el-select>
  <el-radio-group v-if="selectType === 'radio'" class="w-1/1" v-bind="attrs">
    <el-radio v-for="(dict, index) in getDictOptions" :key="index" :value="dict.value">
      {{ dict.label }}
    </el-radio>
  </el-radio-group>
  <el-checkbox-group v-if="selectType === 'checkbox'" class="w-1/1" v-bind="attrs">
    <el-checkbox
      v-for="(dict, index) in getDictOptions"
      :key="index"
      :label="dict.label"
      :value="dict.value"
    />
  </el-checkbox-group>
</template>

<script  setup>
import i18n from "@/language/i18n";
const { t } = i18n.global;
import { getBoolDictOptions, getIntDictOptions, getStrDictOptions } from '@/utils/dict'

const attrs = useAttrs();

// 接受父组件参数
const props = withDefaults(defineProps(), {
  valueType: 'str',
  selectType: 'select'
});

// 获得字典配置
const getDictOptions = computed(() => {
  switch (props.valueType) {
    case 'str':
      return getStrDictOptions(props.dictType);
    case 'int':
      return getIntDictOptions(props.dictType);
    case 'bool':
      return getBoolDictOptions(props.dictType);
    default:
      return [];
  }
});

</script>
