
import i18n from "@/language/i18n";
const { t } = i18n.global;export const isEmpty = (val) => {
  if (val === null || val === undefined) {
    return true;
  }
  if (isArray(val) || isString(val)) {
    return val.length === 0;
  }

  if (val instanceof Map || val instanceof Set) {
    return val.size === 0;
  }

  if (isObject(val)) {
    return Object.keys(val).length === 0;
  }

  return false;
}

export const isArray = (val) => {
  return val && Array.isArray(val);
}

export const isObject = (val) => {
  return val !== null && is(val, 'Object');
}
