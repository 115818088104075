
import i18n from "@/language/i18n";
const { t } = i18n.global;const selectRule = [
  {
    type: 'select',
    field: 'selectType',
    title: t('tran_1571'),
    value: 'select',
    options: [
      { label: t('tran_2248'), value: 'select' },
      { label: t('tran_1075'), value: 'radio' },
      { label: t('tran_1972'), value: 'checkbox' }
    ],
    // 参考 https://www.form-create.com/v3/guide/control 组件联动，单选框和多选框不需要多选属性
    control: [
      {
        value: 'select',
        condition: '==',
        method: 'hidden',
        rule: [
          'multiple',
          'clearable',
          'collapseTags',
          'multipleLimit',
          'allowCreate',
          'filterable',
          'noMatchText',
          'remote',
          'remoteMethod',
          'reserveKeyword',
          'defaultFirstOption',
          'automaticDropdown'
        ]
      }
    ]
  },
  {
    type: 'switch',
    field: 'filterable',
    title: t('tran_3166')
  },
  { type: 'switch', field: 'multiple', title: t('tran_2979') },
  {
    type: 'switch',
    field: 'disabled',
    title: t('tran_1093')
  },
  { type: 'switch', field: 'clearable', title: t('tran_1295') },
  {
    type: 'switch',
    field: 'collapseTags',
    title: t('tran_965')
  },
  {
    type: 'inputNumber',
    field: 'multipleLimit',
    title: t('tran_155'),
    props: { min: 0 }
  },
  {
    type: 'input',
    field: 'autocomplete',
    title: 'autocomplete 属性'
  },
  { type: 'input', field: 'placeholder', title: t('tran_1874') },
  { type: 'switch', field: 'allowCreate', title: t('tran_2753') },
  {
    type: 'input',
    field: 'noMatchText',
    title: t('tran_61')
  },
  { type: 'input', field: 'noDataText', title: t('tran_3260') },
  {
    type: 'switch',
    field: 'reserveKeyword',
    title: t('tran_845')
  },
  {
    type: 'switch',
    field: 'defaultFirstOption',
    title: t('tran_1352')
  },
  {
    type: 'switch',
    field: 'popperAppendToBody',
    title: t('tran_1721'),
    value: true
  },
  {
    type: 'switch',
    field: 'automaticDropdown',
    title: t('tran_2061')
  }
]

const apiSelectRule = [
  {
    type: 'input',
    field: 'url',
    title: 'url 地址',
    props: {
      placeholder: '/system/user/simple-list'
    }
  },
  {
    type: 'select',
    field: 'method',
    title: t('tran_1220'),
    value: 'GET',
    options: [
      { label: 'GET', value: 'GET' },
      { label: 'POST', value: 'POST' }
    ],
    control: [
      {
        value: 'GET',
        condition: '!=',
        method: 'hidden',
        rule: [
          {
            type: 'input',
            field: 'data',
            title: t('tran_28'),
            props: {
              autosize: true,
              type: 'textarea',
              placeholder: '{"type": 1}'
            }
          }
        ]
      }
    ]
  },
  {
    type: 'input',
    field: 'labelField',
    title: 'label 属性',
    info: '可以使用 el 表达式：${属性}，来实现复杂数据组合。如：${nickname}-${id}',
    props: {
      placeholder: 'nickname'
    }
  },
  {
    type: 'input',
    field: 'valueField',
    title: 'value 属性',
    info: '可以使用 el 表达式：${属性}，来实现复杂数据组合。如：${nickname}-${id}',
    props: {
      placeholder: 'id'
    }
  },
  {
    type: 'input',
    field: 'parseFunc',
    title: t('tran_1861'),
    info: `data 为接口返回值,需要写一个匿名函数解析返回值为选择器 options 列表
    (data: any)=>{ label: string; value: any }[]`,
    props: {
      autosize: true,
      rows: { minRows: 2, maxRows: 6 },
      type: 'textarea',
      placeholder: `
        function (data) {
            return data.list.map(item=> ({label: item.nickname,value: item.id}))
        }`
    }
  },
  {
    type: 'switch',
    field: 'remote',
    info: t('tran_3166'),
    title: t('tran_1961')
  },
  {
    type: 'input',
    field: 'remoteField',
    title: t('tran_1171'),
    info: t('tran_2563')
  }
]

export { selectRule, apiSelectRule }
