<template>
  <el-drawer :with-header="title!=null" v-bind="$attrs" class="h-drawer"
             :size="size" :modelValue="modelValue"  :show-close="false" :close-on-click-modal="false">

    <template #header>
      <div class="el-drawer-title">
        {{title}}
      </div>
    </template>
    <template #default>
      <div  @click="cancelHandle" class="h-drawer-close">{{cancelText}}</div>
      <slot></slot>
    </template>
    <template #footer>
      <div  style="text-align: left">
        <el-button @click="confirmHandle" type="primary">{{confirmText}}</el-button>
        <slot name="footer"></slot>
      </div>
    </template>
  </el-drawer>
</template>

<script setup name="HDrawer">
import i18n from "@/language/i18n";
const { t } = i18n.global;
const emit = defineEmits(['confirm','cancel'])
const prop = defineProps({
  title:{
    type:String,
    default:null
  },
  modelValue:{
    default:false,
  },
  size:{
    type:[String,Number],
    default:"60%"
  },
  confirmText:{
    default:"confirm",
  },
  cancelText:{
    default:"cancel"
  }
})
const confirmHandle =()=>{
    emit('confirm')
}
const cancelHandle=()=>{
  emit('cancel')
}
</script>

<style scoped lang="scss">
:global(.h-drawer){
  overflow: initial!important;
}
.h-drawer-close{
  position: absolute;
  background-color: #F56C6C;
  width: 38px;
  letter-spacing: 8px;
  padding: 30px 6px 28px 13px;
  text-align: center;
  left: -38px;
  right: 0;
  top: 10%;
  color: #fff;
  border-radius: 6px 0 0 6px;
  cursor: pointer;
  border: 2px solid #fff;
  border-right: none;
  font-weight: 600;
  font-size: 14px;
  &:hover{
    opacity: 0.9;
  }
}
:global(.el-drawer__header){
  margin-bottom: 0;
}
.el-drawer-title{
  font-size: 20px;
  color: #000;
}
</style>
