<template>
  <router-view></router-view>
</template>

<script setup name="otherview">
import i18n from "@/language/i18n";
const { t } = i18n.global;

</script>

<style scoped>

</style>
